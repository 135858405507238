import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from "react-redux";
import { useState } from 'react';

import '../../components/bundle/Bundle.scss'
import Layout from "../../components/layout/Layout";
import BundlesCard from "../../components/bundle/bundlesCard";
import { getBundleslist, getCountriesList } from '../../services/api';
import BundleDetailsPopup from "../../components/bundle/BundleDetailsPopup";
import CountrySearch from "../../components/form/CountrySearch";



const TopUp = () => {
  const location = useLocation();
  const { iccid, providerCode } = location.state ?? {}
  return (
    <Layout>
      <h1 style={{ color: "white", textAlign: "center" }}>ICCID: {iccid}</h1>
      <TopupBundleSection iccid={iccid} providerCode={providerCode} />
    </Layout>
  );
};

export default TopUp;


const TopupBundleSection = ({ iccid, providerCode }) => {
  const {labels} = useSelector(state=>state.language?.activelang);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [countries, setCountries] = useState([])
  const [veiwDetails, setViewDetails] = useState(null);
  const [filterTabList, setFilterTabList] = useState([]);
  const [currentFilterTab, setCurrentFilterTab] = useState([]);
  const [bundleFilterList, setBundleFilterList] = useState(null)
  const navigate = useNavigate();

  const { isLoading: countriesLoading, error, isFetching: isFetchingCountry } = useQuery({
    enabled: !selectedCountry || !countries?.length,
    queryFn: () => getCountriesList("countries?iccid=" + iccid),
    queryKey: ['getTopupContries'],
    onSuccess: o => { setCountries(o); setSelectedCountry(o?.[0]) },
  });

  const { data: bundleList, isLoading: bundleListLoading, error: bundleListError, isFetching: isFetchingBundle } = useQuery({
    queryFn: () => getBundleslist(`countries=${encodeURIComponent(selectedCountry?.iso)}&page=&limit=&direction=asc&orderBy=price&filter=&filterBy=&iccid=${iccid ?? ""}`),
    queryKey: selectedCountry?.iso,
    enabled: !!selectedCountry?.iso,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (!iccid) return
      let filter = [];
      data.bundles?.forEach((bundle) => {
        if (!filter.includes(bundle.dataAmountForDisplay)) {
          filter.push(bundle.dataAmountForDisplay);
        }
      });
      const getNumericValue = (str) => {
        const match = str.match(/(\d+)/);
        return match ? parseInt(match[1], 10) : Infinity;
      };
      const sortedList = filter.sort((a, b) => {
        const numA = getNumericValue(a);
        const numB = getNumericValue(b);
        if (numA === Infinity) return 1;
        if (numB === Infinity) return -1;
        return numA - numB;
      });
      setFilterTabList(sortedList);
      setBundleFilterList(data?.bundles?.sort((a, b) => a.price - b.price));
    },
  });


  const filterBundle = (tabs) =>
    bundleList?.bundles?.filter((bundle) =>
      tabs.length === 0 ? true : tabs.includes(bundle.dataAmountForDisplay)
    );

  const handleFilterTab = (tab) => {
    let filterTab = [];
    if (currentFilterTab.includes(tab)) {
      setCurrentFilterTab((tabs) => {
        filterTab = tabs.filter((currentTab) => currentTab !== tab);
        return filterTab;
      });
    } else {
      setCurrentFilterTab((tabs) => {
        filterTab = [...tabs, tab];
        return filterTab;
      });
    }
    setBundleFilterList(() => filterBundle(filterTab));
  };

  const bundleload = bundleListLoading || bundleListError || countriesLoading || (!bundleFilterList && (isFetchingCountry || isFetchingBundle));
  const bundleListData = bundleload ? new Array(3).fill(' ') : bundleFilterList?.filter(el => el.providerCode === providerCode)

    const buyNowHanlder = data => navigate('/payment', {state:{bundle:{...data,iccid}}});
   
    return <section className="bundles-section" id='plans'>
        <div className="flex align-items-center justify-content-center gap-4">
            <CountrySearch initial={selectedCountry?.name} placeholder={labels?.LBLN0251} countries={countries} onSelected={ v => setSelectedCountry(v) } />
        </div>
        {
          error && <h2 style={{color:"white", textAlign:"center"}}>{labels?.LBLN0263}</h2>
        }
          <div className="filter-tab">
            {filterTabList.map((tab, index) => (
              <p
                key={index}
                onClick={handleFilterTab.bind(null, tab)}
                className={
                  currentFilterTab.includes(tab) ? "active-p" : "inactive-p"
                }
              >
                {tab}
              </p>
            ))}
          </div>
        <div className='bundles-list-section'>
            <div className="grid justify-content-center">
                {bundleListData?.map?.((item,index) =>
                    <div key={index} className="col-12 md:col-6 sm:col-6 lg:col-4">
                        <BundlesCard labels={labels} iso={selectedCountry?.iso}  onClickBuyNow={buyNowHanlder} onClickDetails={setViewDetails} isLoading={bundleload} data={item} iccid={iccid} currency={bundleList?.currencySymbol} doNotSell={bundleList?.doNotSell}/>
                    </div>
                )}
            </div>
        </div>
        <BundleDetailsPopup onClickBuyNow={buyNowHanlder} onClickCancle={setViewDetails} currency={bundleList?.currencySymbol} data={veiwDetails} iccid={iccid} doNotSell={bundleList?.doNotSell} />
    </section>
}