import React, { useEffect } from 'react';
import Header from "./HeaderMenu";
import Footer from "./Footer";
import { useLocation } from 'react-router-dom';

const Layout = ({children}) => {
    
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    useEffect(() => {
        const currentVersion = process.env.REACT_APP_VERSION;
        const cachedVersion = localStorage.getItem('appVersion');

        if (!cachedVersion || (cachedVersion !== currentVersion)) {
            // Clear outdated cache and update to the new version
            localStorage.setItem('appVersion', currentVersion);
            // Avoid infinite reloads
            if (cachedVersion) {
                window.location.reload(true);
            }
        }
    }, [children]);

    return (
        <>
            <div className='main-section'>
                <Header/>
                    {children}
                <Footer />
            </div>
        </>
    );
};

export default Layout;