import { Link } from 'react-router-dom'
import { flag } from "country-emoji";

import * as PR from '../../prime-modules'
import "./Bundle.scss";
import { setNumberPrecision } from '../../utils/common';
import getAssets from "../../assets/data/brandingAssets.json"

let { signal_icon,global_country } = getAssets.icons

const BundlesCard = ({ data, iso, currency, onClickBuyNow, onClickDetails, isLoading, labels, doNotSell, iccid}) => {

    const { price, dataAmountForDisplay, duration } = data ?? {}

    if (isLoading) return <BundlesCardLoader />

    return (
        <div className='bundle-box'>
            {/* <div className='popular-tag'>
                <i className="pi pi-star-fill"></i>
                <span>Popular</span>
            </div> */}
            <div className='flex align-items-center justify-content-between mb-5'>
                {flag(iso)
                    ? <div className="flag-icon">{flag(iso)}</div>
                    : <PR.Image src={global_country} alt="country flag" className="flag-icon" />
                }
                <div className='flex gap-2'>
                    <PR.Image src={signal_icon} alt="country flag" className="icon" />
                    <h2>{dataAmountForDisplay}</h2>
                </div>
            </div>
            <h2>{duration} {duration > 1 ? labels?.LBLN0254: labels?.LBLN0252} {labels?.LBLN0255}</h2>
            <h3>{currency}{setNumberPrecision(price)}</h3>
            <div className='flex align-items-center gap-5'>
                <PR.Button onClick={onClickBuyNow.bind(null,data)} disabled={!iccid && doNotSell} label={labels?.LBLN0032}className="buy-button" />
                <Link onClick={e=>{e.preventDefault(); onClickDetails(data)}}>{labels?.LBLN0111}</Link>
            </div>
        </div>
    )
}
export default BundlesCard;

const BundlesCardLoader = () => {
    return <div className='bundle-box'>
        <div className='flex align-items-center justify-content-between mb-5'>
            <PR.Skeleton width='70px' height='70px' shape='circle'  ></PR.Skeleton>
            <div className='flex gap-2'>
                <PR.Image src={signal_icon} alt="country flag" className="icon" />
                <PR.Skeleton width="65px" height="23px" ></PR.Skeleton>
            </div>
        </div>
        <PR.Skeleton width="100%" height="24px" ></PR.Skeleton>
        <div style={{ margin: '1.2rem 0rem 2rem' }}>
            <PR.Skeleton width="100%" height="55px" ></PR.Skeleton>
        </div>
        <div className='flex align-items-center gap-5'>
            <PR.Skeleton width="155px" height="57px" ></PR.Skeleton>
            <PR.Skeleton width="84px" height="23px" ></PR.Skeleton>
        </div>
    </div>
}
