import React, { createContext, useContext, useRef } from 'react';
import * as PR from "../prime-modules/index"
import { globalConfig } from '../constants';
import { useSelector } from 'react-redux';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useRef(null);
  const catalogIds = useSelector(state => state.language?.activelang?.messageCatalog);
  const {labels} = useSelector(state => state.language?.activelang);
  const toastKeys = {
      "success": "LBLN0143",
      "error": "LBLN0144",
      "info": "LBLN0145"
    }
  
  const showToast  = (severity, messageId) => {
    const id = toastKeys[severity];
    if (toast.current) {
        toast.current.clear()
        toast.current.show({
            life: severity === "error" ? globalConfig.errorToastDisplayTime : globalConfig.toastDisplayTime,
            severity: severity,
            summary: labels[id],
            detail: catalogIds[messageId] ? catalogIds[messageId] :  messageId,
        });
    }
  };
  const showMessage = (messageId) => catalogIds[messageId] ? catalogIds[messageId] :  messageId;


  return (
    <ToastContext.Provider value={{showToast, showMessage}}>
      <PR.Toast ref={toast} position="top-right" baseZIndex={9999} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};